import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAction, useTranslate } from "@helpers/hooks";
import { useHistory } from "react-router-dom";
import { MOCK_EXAMS } from "@utils/consts";
import {
  convertQuestions,
  getEarnedCoins,
  notify,
  showQuote,
  startCoinAnimation,
} from "@utils/functions";
import {
  CompletedModal,
  MockHeader,
  MockNavigation,
  MockWarningModal,
  QuizQuestionItem,
} from "@components/feature";
import Confetti from "react-confetti";
import { toast } from "react-toastify";
import "./MockExamContent.scss";


const INITIAL_INDICES = {
  math: 0,
  crit: 0,
  module1: 0,
  module2: 0,
  module3: 0,
  module4: 0,
};

export default function MockExamContent() {
  const {
    mock,  // this component assumes mock is never null
    stage,  // this component assumes stage is never null
    mockProgressId,  // this component assumes mockProgressId is never null
    mockEarnedCoins,
    crit,
    math,
    verbalOne,
    verbalTwo,
    mathOne,
    mathTwo,
  } = useSelector((state) => state.mocks);
  const isNuet = mock.type == "nuet_mock";
  const nuetMockId = mock.nuet_mock_exam?.id;
  const satMockId = mock.sat_mock_exam?.id;
  const isFinished = stage === "finished";
  const { t, i18n } = useTranslate();
  const {
    fetchCrit,
    fetchMath,
    submitMath,
    submitCrit,
    getProgress,
    getSatProgress,
    fetchVerbalOne,
    fetchVerbalTwo,
    fetchMathOne,
    fetchMathTwo,
    submitVerbalOne,
    submitVerbalTwo,
    submitMathOne,
    submitMathTwo,
    fetchMockExam,
  } = useAction();
  const { mockId } = useParams();
  const history = useHistory();
  const [showCoins, setShowCoins] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(isNuet ? "math" : "module1");
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [userAnswers, setUserAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem(
      `mock_user_answers_${mockProgressId}_${stage}`
    );
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });
  const userResponsesCount = Object.values(userAnswers).length;

  useEffect(() => {
    const mockExamId = isNuet ? nuetMockId : satMockId;

    const fetchFunctions = isNuet
      ? {
          math: fetchMath,
          crit: fetchCrit,
        }
      : {
          module1: fetchVerbalOne,
          module2: fetchVerbalTwo,
          module3: fetchMathOne,
          module4: fetchMathTwo,
        };

    if (isFinished) {
      Object.values(fetchFunctions).forEach((fetchFn) => fetchFn(mockExamId));
    } else if (fetchFunctions[stage]) {
      fetchFunctions[stage](mockExamId);
    }
  }, [stage]);

  // We need to add math, crit, module1, module2, module3, module4 to dependencies array,
  // because they are usually null in the beginning and only get set after fetchFunctions are called.
  const questionsData = useMemo(() => {
    const sectionToDataMap = {
      math: math,
      crit: crit,
      module1: verbalOne,
      module2: verbalTwo,
      module3: mathOne,
      module4: mathTwo,
    };
    const sectionData = sectionToDataMap[currentSection];
    if (sectionData && sectionData.length > 0) {
      return convertQuestions(sectionData[0].questions);
    }
    return [];
  }, [
    math,
    crit,
    verbalOne,
    verbalTwo,
    mathOne,
    mathTwo,
    currentSection,
  ]);
  const questionsCount = questionsData.length;

  const useQuestionIndices = () => {
    const [indices, setIndices] = useState(INITIAL_INDICES);

    const setCurrentIndex = (section, index) => {
      if (indices.hasOwnProperty(section)) {
        setIndices({ ...indices, [section]: index });
      }
    };

    const handleNavigation = (direction) => {
      const currentIndex = indices[currentSection];
      const newIndex =  
        direction === "next" ? currentIndex + 1 : currentIndex - 1;

      if (newIndex >= 0 && newIndex < questionsCount) {
        setCurrentIndex(currentSection, newIndex);
      }
    };

    return { indices, setCurrentIndex, handleNavigation };
  };

  const { indices, setCurrentIndex, handleNavigation } = useQuestionIndices();
  const currentQuestionIndex = indices[currentSection];
  const handleNextQuestion = () => handleNavigation("next");
  const handlePreviousQuestion = () => handleNavigation("prev");
  const setCurrentQuestionIndex = (index) => {
    setCurrentIndex(currentSection, index);
  };

  useEffect(() => {
    if (stage === "finished") {
      setCurrentSection(isNuet ? "math" : "module1");
    } else {
      setCurrentSection(stage);
    }
  }, [stage]);

  useEffect(() => {
    setCurrentQuestionIndex(0);
  }, [currentSection]);

  const celebrateCompletion = () => {
    showQuote(1);
    setConfetti(true);
    setTimeout(() => {
      setConfetti(false);
    }, 5000);
  };

  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionId]: answer };
      localStorage.setItem(
        `mock_user_answers_${mockProgressId}_${stage}`,
        JSON.stringify(newAnswers)
      );
      return newAnswers;
    });
  };

  let score;
  switch (isNuet) {
    case true:
      score = math && crit && [math[0]?.score, crit[0]?.score];
      break;
    case false:
      score = mathOne &&
        mathTwo &&
        verbalOne &&
        verbalTwo && [
          mathOne[0]?.total_math_score,
          verbalOne[0]?.total_verbal_score,
        ];
      break;
    default:
      score = "...";
  }

  const handleRedirect = () => {
    history.push(MOCK_EXAMS);
  };

  const handleOpenPopup = () => {
    setIsCompletedModalOpen(true);
    celebrateCompletion();
  };

  const handleClosePopup = async () => {
    await fetchMockExam(mockId);  // TODO: Do we need this?
    setConfetti(false);
    setIsCompletedModalOpen(false);
  };

  const handleSubmit = useCallback(async () => {
    // Filter out null answers and ensure question ID exists in questionsData
    // This is a temporary solution.
    // Due to some reason there are some invalid question IDs in userAnswers and
    // we haven't yet figured out why.
    const validQuestionIds = questionsData.map(question => question.id);
    const result = Object.entries(userAnswers)
      .filter(([questionId]) => validQuestionIds.includes(parseInt(questionId, 10)))
      .map(([questionId, answer]) => ({
        answer,
        user_id: 0,
        question_id: parseInt(questionId, 10),
      }));

    const requestBody = {
      responses: result,
      validQuestionIds,
      questionsData,
      userAnswers,
      mockProgressId,
      stage,
      currentSection,
      math,
      crit,
      verbalOne,
      verbalTwo,
      mathOne,
      mathTwo
    };

    const sectionConfig = {
      math: { submitFn: submitMath, id: mock.nuet_mock_exam?.id },
      crit: {
        submitFn: submitCrit,
        id: mock.nuet_mock_exam?.id,
        openPopup: true,
      },
      module1: { submitFn: submitVerbalOne, id: mock.sat_mock_exam?.id },
      module2: { submitFn: submitVerbalTwo, id: mock.sat_mock_exam?.id },
      module3: { submitFn: submitMathOne, id: mock.sat_mock_exam?.id },
      module4: {
        submitFn: submitMathTwo,
        id: mock.sat_mock_exam?.id,
        openPopup: true,
      },
    };

    const config = sectionConfig[currentSection];
    try {
      await config.submitFn({
        id: config.id,
        data: JSON.stringify(requestBody),
      });
      
      localStorage.removeItem(`mock_user_answers_${mockProgressId}_${stage}`);
      setUserAnswers({});

      if (config.openPopup) {
        /*
        * The showCoins flag ensures coins are only displayed once when earned.
        * Without this flag, coins would show every time mockEarnedCoins exists in state,
        * including on component re-renders. Instead, we only want to show them
        * immediately after the backend confirms the mock completion.
        */
        setShowCoins(true);
        setTimeout(() => {
          handleOpenPopup();
        }, 1000);
        /*
        * We set timeout to 1 second so that the popup is opened after the coins toast is shown.
        */
      }

      if (isNuet) {
        await getProgress(nuetMockId);
      } else {
        await getSatProgress(satMockId);
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
      toast.error(t("MOCK.SUBMIT_ERROR"));
      return;
    }
  }, [
    questionsData,
    userAnswers,
    mockProgressId,
    stage,
    currentSection,
    math,
    crit,
    verbalOne,
    verbalTwo,
    mathOne,
    mathTwo,
    submitMath,
    submitCrit,
    submitVerbalOne,
    submitVerbalTwo,
    submitMathOne,
    submitMathTwo,
    getProgress,
    getSatProgress,
  ]);

  const handleModal = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
  };

  const handleButtonAnyway = () => {
    // handleSubmit is async, but for now we just call it synchronously
    // If it fails, user will see that nothing happens (e.g. no redirect to next section or no popup)
    // and can retry again
    handleSubmit();
    handleModal();
  };

  useEffect(() => {
    if (mockEarnedCoins && showCoins) {
      const language = i18n.language;
      const coinsText = getEarnedCoins(language, mockEarnedCoins);
      notify(coinsText, "success", true);
      startCoinAnimation(5000);
      setShowCoins(false);
      /*
       * Now the showCoins flag is set to false, so the coins will not show again
       * unless the mockEarnedCoins state changes.
       */
    }
  }, [mockEarnedCoins, showCoins]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="ongoing__container">
      <MockHeader
        isNuet={isNuet}
        handleSubmit={handleSubmit}
        handleRedirect={handleRedirect}
        score={score}
        userResponsesCount={userResponsesCount}
        questionsData={questionsData}
        currentSection={currentSection}
        isModalOpen={isWarningModalOpen}
        setIsModalOpen={setIsWarningModalOpen}
      />
      {confetti && (
        <Confetti
          width={windowWidth}
          height={windowHeight}
          numberOfPieces={1000}
          initialVelocityY={10}
          gravity={0.4}
        />
      )}

      <div className="mock__main">
        <MockNavigation
          userResponsesCount={userResponsesCount}
          score={score}
          mock={mock}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          currentQuestionIndex={currentQuestionIndex}
          stage={stage}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          handleNextQuestion={handleNextQuestion}
          handlePreviousQuestion={handlePreviousQuestion}
          questionsCount={questionsCount}
          userAnswers={userAnswers}
          questionsData={questionsData}
          isNuet={isNuet}
          handleRedirect={handleRedirect}
          handleSubmit={handleSubmit}
          isModalOpen={isWarningModalOpen}
          handleModal={handleModal}
        />
        <form className="questions__form">
          {questionsCount > 0 && (
            <QuizQuestionItem
              question={questionsData[currentQuestionIndex]}
              index={currentQuestionIndex + 1}
              userAnswers={userAnswers}
              handleAnswerChange={handleAnswerChange}
              isHorizontal={true}
              hasQuizAnswers={isFinished}
              disabled={isFinished}
            />
          )}
        </form>
      </div>

      {isCompletedModalOpen && (
        <CompletedModal handleClosePopup={handleClosePopup} />
      )}

      {isWarningModalOpen && (
        <MockWarningModal
          handleModal={handleModal}
          handleButtonAnyway={handleButtonAnyway}
        />
      )}
    </div>
  );
}
