import React, { useState } from "react";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { KASPI_PAYMENT_ROUTE, MOCK_EXAM_ONGOING } from "@utils/consts";
import {
  Tabs,
  PageTitle,
  List,
  NewModalLayout,
  Button,
  Icon,
  FormItem,
} from "@components/common";
import {
  Banner,
  BuyMockModal,
  CourseReviewsCard,
  InstructionModal,
  MockCard,
  RatingQuestion,
  UpSale,
} from "@components/feature";

import "./MockSectionContent.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { trackAmplitude } from "@utils/functions";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function MockSectionContent({ mocks }) {
  const isMobile = useIsMobile();
  const { t } = useTranslate();
  const history = useHistory();
  const {
    createMockReview,
    fetchAllMockReviews,
  } = useAction();
  const { mockReviews } = useSelector((state) => state.mocks);

  const [selectedTab, setSelectedTab] = useState("MOCK.NUET");
  const [selectedItem, setSelectedItem] = useState(null);
  const [buyModalItem, setBuyModalItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [isSaleOpen, setIsSaleOpen] = useState(false);
  const [isCreateReviewModalOpen, setIsCreateReviewModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [selectedReviewMock, setSelectedReviewMock] = useState(null);
  const tabs = {
    nuet: "MOCK.NUET",
    sat: "MOCK.SAT",
    ielts: "MOCK.IELTS",
  };

  const tabToExamTypeMap = {
    "MOCK.NUET": "nuet_mock",
    "MOCK.SAT": "sat_mock",
    "MOCK.IELTS": "ielts_mock",
  };

  const filteredExams = mocks.filter(
    (item) => item.type === tabToExamTypeMap[selectedTab]
  );

  const handleStart = (item) => {
    trackAmplitude("mock_card_start_click", {
      description: "Пользователь начал проходить мок-экзамен",
      mock_name: item.title,
    });

    const examKey = `${item.type}_exam`;
    setSelectedItem(item);
    item[examKey]?.completed
      ? history.push(MOCK_EXAM_ONGOING.replace(":mockId", item.id))
      : setIsModalOpen(true);
  };

  const handleBuy = (item) => {
    trackAmplitude("mock_card_buy_click", {
      description: "Пользователь кликнул на Купить мок-экзамен",
      mock_name: item.title,
    });

    setBuyModalItem(item);
    localStorage.setItem(
      "mock_purchase",
      JSON.stringify({ mock_id: item.id, access: item.access })
    );
  };

  const handleCloseBanner = () => {
    setBuyModalItem(null);
    localStorage.removeItem("mock_purchase");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleRedirectToKaspi = (item) => {
    history.push(
      KASPI_PAYMENT_ROUTE.replace(":type", "mock")
        .replace(":courseId", item.id)
        .replace(":packageId", 0)
        .replace(":courseName", item.title)
        .replace(":packageType", 0)
        .replace(":price", item.sum)
    );
  };

  const handleCreateReview = () => {
    setIsCreateReviewModalOpen(true);
  };

  const handleSubmitReview = () => {
    const reviewData = {
      comment,
      rating,
      // Add other necessary data here
    };
    createMockReview({ id: selectedReviewMock, data: reviewData });
    setIsCreateReviewModalOpen(false);
  };

  useEffect(() => {
    fetchAllMockReviews();
  }, []);


  return (
    <div className="section__group-mock">
      <PageTitle title={"MOCK.TITLE"} />

      {isSaleOpen && (
        <UpSale isSaleOpen={isSaleOpen} setIsSaleOpen={setIsSaleOpen} />
      )}
      {isBannerOpen && (
        <Banner isBannerOpen={isBannerOpen} setIsBannerOpen={setIsBannerOpen} />
      )}

      
        <Tabs
          className={"catalog-tabs"}
          tabClassName={"catalog-tabs__tab "}
          tabs={[tabs.nuet, tabs.sat, tabs.ielts]}
          selected={selectedTab}
          setSelected={setSelectedTab}
        />
    
      

      {mockReviews && (
        <div className="mock__reviews-container">
          <div className="mock__reviews__headline-container">
            <h2 className="mock__reviews__headline">Reviews</h2>
            <Button
              className="btn--primary btn--pill btn--small"
              onClick={handleCreateReview}
              text={t("LMS.REVIEW.CREATE")}
            />
          </div>
          <List
            className="mock__reviews"
            items={mockReviews}
            renderItem={(item) => (
              <CourseReviewsCard key={item.id} review={item} />
            )}
          />
        </div>
      )}

      <List
        items={filteredExams}
        className="mock__cards"
        renderItem={(item) => (
          <MockCard
            key={item.id}
            item={item}
            handleStart={handleStart}
            handleBuy={handleBuy}
          />
        )}
      />

      {isModalOpen && selectedItem && (
        <InstructionModal
          isMobile={isMobile}
          selectedItem={selectedItem}
          onClose={handleCloseModal}
        />
      )}

      {buyModalItem && (
        <BuyMockModal
          isMobile={isMobile}
          buyModalItem={buyModalItem}
          handleRedirectToKaspi={handleRedirectToKaspi}
          onClose={handleCloseBanner}
        />
      )}

      {isCreateReviewModalOpen && (
        <NewModalLayout>
          <div className="reviews__modal-container">
            <div className="reviews__modal-header">
              <h1 className="reviews__modal-title">Create review</h1>
              <Icon
                name="close-rounded"
                handleClick={() => setIsCreateReviewModalOpen(false)}
              />
            </div>
            <div className="reviews__modal-content">
              <FormItem
                item={{
                  id: 1,
                  field: "dropdown",
                  name: "mock",
                  value: selectedReviewMock?.title,
                  placeholder: "Select mock",
                  options: mocks.map((mock) => ({
                    id: mock.id,
                    name: mock.title,
                    value: mock.id,
                  })),
                }}
                handleChange={(e) => setSelectedReviewMock(e.value)}
              />
              {selectedReviewMock && (
                <>
                  <RatingQuestion
                    userAnswer={rating}
                    onAnswerChange={setRating}
                  />

                  <FormItem
                    item={{
                      id: 2,
                      inputClassName: "reviews__modal-input",
                      placeholder: t("LMS.REVIEW.COMMENT"),
                      field: "input",
                      name: "comment",
                      value: comment,
                      type: "text",
                      isTextArea: true,
                    }}
                    handleChange={(e) => setComment(e.target.value)}
                  />
                </>
              )}

              <Button
                className="btn--primary btn--pill"
                text={t("LMS.REVIEW.CREATE")}
                onClick={handleSubmitReview}
              />
            </div>
          </div>
        </NewModalLayout>
      )}
    </div>
  );
}
