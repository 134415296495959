import { axiosRequest } from "@api/xhr";

export default class ReferralService {
  static async getOrCreateReferralCode() {
    return await axiosRequest.post("lms/referral/code/");
  }

  static async activateReferralCode(data) {
    return await axiosRequest.post("lms/referral/activate/", data);
  }

  static async getReferralCodeActivated() {
    return await axiosRequest.get("lms/referral/activated/");
  }
}
