// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add_modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 200000;
  background-color: rgba(0, 0, 0, 0.4);
}
.add_modal_content {
  border-radius: 40px;
  background-color: white;
  padding: 32px 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: none;
  margin: 0px;
}

@media screen and (max-width: 481px) {
  .add_modal {
    align-items: center !important;
  }
}
.add-modal-courses-tariffs {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 200000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-modal-courses-tariffs_content {
  border-radius: 40px;
  background-color: white;
  padding: 32px 48px;
  margin: 20px 0;
  max-height: 90vh;
  overflow-y: auto;
}

@media screen and (max-width: 481px) {
  .add-modal-courses-tariffs {
    align-items: center !important;
  }
  .add-modal-courses-tariffs_content {
    margin: 20px;
    padding: 24px 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/NewModalLayout/NewModalLayout.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,oCAAA;AADF;AAGE;EACE,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,eAAA;EACA,WAAA;AADJ;;AAKA;EACE;IACE,8BAAA;EAFF;AACF;AAOA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AALF;AAOE;EACE,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AALJ;;AAUA;EACE;IACE,8BAAA;EAPF;EAQE;IACE,YAAA;IACA,kBAAA;EANJ;AACF","sourcesContent":["@import \"@styles/variables\";\n\n.add_modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  overflow: auto;\n  z-index: 200000;\n  background-color: rgba(0, 0, 0, 0.4);\n\n  &_content {\n    border-radius: 40px;\n    background-color: white;\n    padding: 32px 48px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    min-width: none;\n    margin: 0px;\n  }\n}\n\n@media screen and (max-width: 481px) {\n  .add_modal {\n    align-items: center !important;\n    &_content {\n    }\n  }\n}\n\n.add-modal-courses-tariffs {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  overflow: auto;\n  z-index: 200000;\n  background-color: rgba(0, 0, 0, 0.4);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &_content {\n    border-radius: 40px;\n    background-color: white;\n    padding: 32px 48px;\n    margin: 20px 0;\n    max-height: 90vh;\n    overflow-y: auto;\n  }\n}\n\n\n@media screen and (max-width: 481px) {\n  .add-modal-courses-tariffs {\n    align-items: center !important;\n    &_content {\n      margin: 20px;\n      padding: 24px 32px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
