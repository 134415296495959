import { Author } from "./Author";
import { useAction, useTranslate } from "@helpers/hooks";
import "./AboutCourse.scss";
import { Button, ButtonLink, List } from "@components/common";
import { useEffect } from "react";
import { useState } from "react";
import { PopupTariffs } from "@components/feature/Tariffs";
import { trackAmplitude } from "@utils/functions";
import { CourseLevelBadge } from "@components/feature/Courses";
import { useSelector } from "react-redux";
import { COURSE_ROUTE } from "@utils/consts";

export default function AboutCourse() {
  const { t } = useTranslate();
  const [isFixed, setIsFixed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const { getPaymentLinks } = useAction();
  const { course } = useSelector((state) => state.courses);
  const isMyCourse = course.enrolled;
  const descriptionItems = parseDescription(course.description);

  const onBuyClick = async () => {
    try {
      const paymentLinks = await getPaymentLinks({ courseId: course.id });
      setPaymentInfo(paymentLinks.payload);
      setIsModalOpen(true);
      localStorage.setItem(
        "course_purchase",
        JSON.stringify({ course_id: course.id, enrolled: course.enrolled })
      );
    } catch (error) {
      console.error("Error fetching payment links:", error);
    } finally {
      trackAmplitude("learning_platfrom_learn_click", {
        description: "Пользователь успешно открыл тарифы курса для покупки",
        course_name: course.title,
      });
    }
  };

  const handleLinkClick = () => {
    trackAmplitude("learning_platfrom_get_started_click", {
      description: "Пользователь успешно начал свой курс",
      course_name: course.title,
    });
  };

  function formatCurrency(amount) {
    return amount.toLocaleString("en-US") + " ₸";
  }

  function parseDescription(description) {
    return description?.split("\r\n").map((item, index) => ({
      id: index,
      text: item.trim(),
    }));
  }

  const getCourseDisplayPrice = (priceData) => {
    if (!priceData) return null;
    return priceData.price_with_discount || priceData.price_per_recur;
  };

  function findSmallestPrice(data) {
    let minPrice = Infinity;
    for (const item of data) {
      if (item.plantype) {
        const displayPrice = getCourseDisplayPrice(item.plantype);
        if (displayPrice < minPrice) {
          minPrice = displayPrice;
        }
      }
    }
    return minPrice;
  }

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("header");
      const headerBottom = header ? header.offsetHeight : 0;
      const scrollPosition = window.scrollY;

      if (scrollPosition >= headerBottom + 60) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="about-course">
      {isFixed && <div className="about-course-fixed-placeholder"></div>}
      <div className={`about-course-fixed ${isFixed ? "fixed-bottom" : ""}`}>
        <div className="about-course-badge-div">
          <p className="about-course-title">{course.title}</p>
          <div>
            <CourseLevelBadge level={course.level} />
          </div>
        </div>
        <div className="about-course-price-box">
          <p className="about-course-price">
            {formatCurrency(findSmallestPrice(course.package_types))}
          </p>
          {isMyCourse ? (
            <ButtonLink
              className="about-course__button about-course__button_my btn--primary btn--pill"
              link={`${COURSE_ROUTE.replace(
                ":courseId",
                course.id
              )}/sections/0/onboarding`}
              text={t("LMS.BUTTON.GET_STARTED")}
              onClick={handleLinkClick}
            />
          ) : (
            <Button
              className="btn--primary btn--pill"
              text={t("BUTTON.BUY_COURSE")}
              onClick={() => onBuyClick()}
            />
          )}
        </div>
      </div>
      <div className="about-course-upper">
        <Author author={course.author} />
        <div className="about-course-container">
          <p className="about-course-about">{t("LMS.COURSE.INFO.ABOUT")}</p>
          <List
            items={descriptionItems}
            renderItem={(item) => (
              <li key={item.id} className={"about-course-about-item"}>
                <p>{item.text}</p>
              </li>
            )}
          />
        </div>
      </div>
      {isModalOpen && (
        <PopupTariffs
          handleClose={() => setIsModalOpen(false)}
          paymentInfo={paymentInfo}
          course={course}
        />
      )}
    </div>
  );
}
