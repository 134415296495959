import {useSelector} from "react-redux";
import {SettingsSecurity, SettingsSkeleton} from "@components/feature";
import "./Settings.scss";

export default function Settings() {
    const {profile} = useSelector(state => state.profile);

    return (
        (profile)
        ? <section className={"settings"}>
            <SettingsSecurity/>
        </section>
        : <SettingsSkeleton/>
    );
};