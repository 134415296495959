import { useState, useMemo } from "react";
import { Document, Page } from "react-pdf";
import "./PdfWindow.scss";

export default function PdfWindow({ link }) {
  const [numPages, setNumPages] = useState();
  
  // Memoize the file object to prevent unnecessary reloads
  const file = useMemo(() => ({ url: link }), [link]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (!link) return null;

  return (
    <div className="pdf__window">
      <Document 
        file={file}  // Use memoized file object instead of direct link
        onLoadSuccess={onDocumentLoadSuccess}
        loading="Loading PDF..."
      >
        {numPages && Array.from({ length: numPages }, (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </div>
  );
}
