import React from "react";
import { useSelector } from "react-redux";
import { MockHeaderScore, MockTimer } from "@components/feature";
import { Button, CustomLink, Icon } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import {
  WHATSAPP_MOCK_NUET,
  WHATSAPP_MOCK_SAT,
  WHATSAPP_MOCK_IELTS,
  WHATSAPP_CHALLENGE,
} from "@utils/consts";
import "./MockHeader.scss";

export default function MockHeader({
  handleSubmit,
  handleRedirect,
  userResponsesCount,
  questionsData,
  score,
  currentReviewSection,
  isModalOpen,
  setIsModalOpen,
}) {
  const {mock, stage} = useSelector((state) => state.mocks);
  const getContactLink = (mock) => {
    const contactLinks = {
      nuet_mock: WHATSAPP_MOCK_NUET,
      sat_mock: WHATSAPP_MOCK_SAT,
      ielts_mock: WHATSAPP_MOCK_IELTS,
      challenge: WHATSAPP_CHALLENGE,
    };

    return contactLinks[mock.type] || "";
  };

  const contactLink = getContactLink(mock);
  const { t } = useTranslate();
  const isMobile = useIsMobile();

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleButtonClick = () => {
    stage === "finished" ? handleRedirect() : handleModal();
  };
  

  const renderSubmitButtonText = () => {
    if (stage === "finished") {
      return t("MOCK.FINISH_REVIEW");
    }
    return t(
      stage === "module4" || stage === "crit" || stage === "speaking"
        ? "MOCK.SUBMIT_EXAM"
        : "MOCK.NEXT_SECTION"
    );
  };

  return (
    <header className="mock__header">
      <nav className="mock__header-nav">
        {isMobile && (
          <Icon name={"arrow-back"} handleClick={handleButtonClick} />
        )}
        <h3 className="mock__ongoing-title">{mock.title}</h3>
        {stage !== "finished" && (
          <MockTimer
            handleSubmit={handleSubmit}
          />
        )}

        <div className="mock__indicators">
          <MockHeaderScore
            currentReviewSection={currentReviewSection}
            userResponsesCount={userResponsesCount}
            questionsData={questionsData}
            score={score}
          />

          {stage === "finished" && (
            <CustomLink
              className={"ongoing__button"}
              isExternal={true}
              newTab={true}
              to={contactLink}
            >
              {t("MOCK.CONTACT")}
            </CustomLink>
          )}
          {!isMobile && (
            <div className="mock__buttons">
              <Button
                className="btn--primary mock__btn"
                text={renderSubmitButtonText()}
                onClick={handleButtonClick}
              />
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}
