import React from "react";
import { useSelector } from "react-redux";
import { useTranslate, useIsMobile } from "@helpers/hooks";

export default function MockHeaderScore({
  currentReviewSection,
  userResponsesCount,
  questionsData,
  score,
}) {
  const { mock, stage } = useSelector((state) => state.mocks);
  const isMobile = useIsMobile();
  const { t } = useTranslate();


  const SAT_MAX_MODULE_SCORE = 800;
  const NUET_MAX_MODULE_SCORE = 120;
  const SAT_MAX_SCORE = 1600;
  const NUET_MAX_SCORE = 240;
  const IELTS_MAX_SCORE = 9;

  const totalQuestionsExcludingImages = questionsData?.filter(
    (question) => question?.type !== "question_images"
  ).length;

  // Determine max score and total score based on mock type
  const scoreMax =
    mock?.type === "nuet_mock"
      ? NUET_MAX_SCORE
      : mock?.type === "ielts_mock"
      ? IELTS_MAX_SCORE
      : SAT_MAX_SCORE;

  const calculateTotalScore = () => {
    if (mock?.type === "ielts_mock") {
      return mock?.ielts_mock_exam?.score;
    } else if (mock?.type === "nuet_mock") {
      return mock?.nuet_mock_exam?.score;
    } else if (mock?.type === "sat_mock") {
      return mock?.sat_mock_exam?.score;
    }
  };
  

  const totalScore = calculateTotalScore() ?? "loading";

  
  // Section-specific logic
  const isSATMath = ["module3", "module4"].includes(currentReviewSection);
  const isNuetMath = currentReviewSection === "math";

  if (isMobile) return null;

  const progressMapping = {
    sat_mock: () => {
      const totalLabel = t("PROFILE.POINT_EMPTY");
      const sectionLabel = isSATMath ? t("MOCK.MATH") : t("MOCK.VERBAL");
      const sectionScore = isSATMath ? score?.[0] : score?.[1];
      return (stage === "finished") ? (
        <>
          <div className="mock__progress">
            {sectionLabel} {sectionScore}/{SAT_MAX_MODULE_SCORE}
          </div>
          <div className="mock__progress">
            {totalLabel} {totalScore}/{SAT_MAX_SCORE}
          </div>
        </>
      ) : (
        <div className="mock__progress">
          {userResponsesCount} / {totalQuestionsExcludingImages}
        </div>
      );
    },
    nuet_mock: () => {
      const totalLabel = t("PROFILE.POINT_EMPTY");
      const sectionLabel = isNuetMath ? "Math" : "Crit";
      const sectionScore = isNuetMath ? score?.[0] : score?.[1];
      return (stage === "finished") ? (
        <>
          <div className="mock__progress">
            {sectionLabel}: {sectionScore}/{NUET_MAX_MODULE_SCORE}
          </div>
          <div className="mock__progress">
            {totalLabel}: {totalScore}/{NUET_MAX_SCORE}
          </div>
        </>
      ) : (
        <div className="mock__progress">
          {userResponsesCount} / {totalQuestionsExcludingImages}
        </div>
      );
    },
    ielts_mock: () => {
      return (stage === "finished") ? (
        <div className="mock__progress">
          {t("PROFILE.POINT_EMPTY")} {totalScore}/{IELTS_MAX_SCORE}
        </div>
      ) : (
        <div className="mock__progress">
          {userResponsesCount} / {totalQuestionsExcludingImages}
        </div>
      );
    },
    default: () => {
      if (stage === "speaking") return null;
      return (
        <div className="mock__progress">
          {stage === "finished"
            ? `${t("PROFILE.POINT_EMPTY")} ${totalScore}/${scoreMax}`
            : `${userResponsesCount} / ${totalQuestionsExcludingImages}`}
        </div>
      );
    },
  };

  const progressContent =
    progressMapping[mock?.type]?.() || progressMapping.default();

  return progressContent ? <>{progressContent}</> : null;
}
