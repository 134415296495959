// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tariff__pop-up {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 auto;
}
.tariff__pop-up-mobile {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px !important;
  height: 100%;
  margin-top: 60px;
  overflow-y: scroll;
}
.tariff__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.tariff__list-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.tariff__title-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tariff__title-button-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Tariffs/PopupTariffs/PopupTariffs.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;AAFJ;AAII;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,wBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAFN;AAME;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AAJJ;AAMI;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,SAAA;AAJN;AAYI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAVN;AAYM;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AAVR","sourcesContent":["@import \"@styles/variables\";\n\n.tariff {\n  &__pop-up {\n    display: flex;\n    flex-direction: column;\n    gap: 25px;\n    margin: 0 auto;\n\n    &-mobile {\n      display: flex;\n      flex-direction: column;\n      gap: 25px;\n      padding: 20px !important;\n      height: 100%;\n      margin-top: 60px;\n      overflow-y: scroll;\n    }\n  }\n\n  &__list {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    gap: 20px;\n\n    &-mobile {\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n      gap: 10px;\n    }\n\n\n\n  }\n\n  &__title {\n    &-button {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      align-items: center;\n\n      &-mobile {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        align-items: center;\n        margin-bottom: 8px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
