import { useEffect } from "react";
import { Icon, NewModalLayout, BtnLoader } from "@components/common";
import { useSelector } from "react-redux";
import { useAction, useTranslate, useIsMobile } from "@helpers/hooks";
import { notify } from "@utils/functions";
import "./ReferralCodeModal.scss";

const ReferralCodeModal = ({ onClose }) => {
  // const { t } = useTranslate();
  const isMobile = useIsMobile();
  const { myReferralCode, isLoading } = useSelector((state) => state.referral);
  const { getOrCreateReferralCode } = useAction();

  useEffect(() => {
    getOrCreateReferralCode();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(myReferralCode);
    notify("Промокод скопирован");
  };

  return (
    <NewModalLayout
      className={`${
        isMobile ? `referral-code-modal__pop-up-mobile` : `referral-code-modal__pop-up`
      }`}
    >
      <div className="referral-code-modal__header">
        <h2 className="">
          Твой промокод!
        </h2>
        <Icon
          name="close-rounded"
          className="referral-code-modal__close"
          handleClick={onClose}
        />
      </div>
      <p>Отправь этот промокод другу для использования при покупке курса. Друг получит 5% скидку, а ты 5% на свою карту.</p>
      <div>
        {isLoading && !myReferralCode ? (
          <BtnLoader className={"btn-loader--blue"} />
        ) : (
          <div className="referral-code-container">
            <span className="referral-code">{myReferralCode}</span>
            <Icon name="copy" handleClick={handleCopy} className="referral-code-container-copy-icon" />
          </div>
        )}
      </div>
    </NewModalLayout>
  );
};

export default ReferralCodeModal;
