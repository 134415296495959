import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { parseArrToStr, trackAmplitude } from "@utils/functions";
import {
  useAction,
  useDebounce,
  useIsMobile,
  useTranslate,
  useWindowSize,
} from "@helpers/hooks";
import { Button, Icon, PageTitle } from "@components/common";
import { CatalogFilter, CatalogOrder, MobileFilter, MobileOrder, CatalogTabs, UniversityDetails, MobilePlaceholder } from "@components/feature";
import "./Catalog.scss";

export default function Catalog() {
  const { t } = useTranslate(),
    windowSize = useWindowSize(),
    { regions } = useSelector((state) => state.directory),
    {
      fetchRegions,
      fetchCountries,
      fetchCities,
      fetchSubjects,
      fetchMajors,
      fetchDegrees,
      fetchMoreInfos,
      fetchDirectoryUniversities,
    } = useAction(),
    [filterShow, setFilterShow] = useState(false),
    [sortShow, setSortShow] = useState(false),
    [search, setSearch] = useState(""),
    [order, setOrder] = useState(null),
    debouncedValue = useDebounce(search, 500),
    tabs = {
      universities: "CATALOG.UNIVERSITIES.TITLE",
      programs: "CATALOG.PROGRAMS.TITLE",
    },
    [selectedTab, setSelectedTab] = useState(tabs.universities),
    [universityId, setUniversityId] = useState(null),
    [universityOpen, setUniversityOpen] = useState(false),
    initialFilters = {
      region: [],
      country: [],
      city: [],
      university: [],
      subject: [],
      primary_study_level: [],
      major: [],
      rating__QS: [],
      duration_field_test: [],
      tuition_fee: [],
      study_mode: [],
      scholarships: [],
    },
    [selectedFilters, setSelectedFilters] = useState(initialFilters),
    [additionalParams, setAdditionalParams] = useState(null);

  const [isResetFilter, setIsResetFilter] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const isMobile = useIsMobile();

  useEffect(() => {
    fetchRegions();
    trackAmplitude("university_program_search_visit", {description:'Пользователь зашел на страницу "University/Program search"'})
  }, []);

  useEffect(() => {
    fetchCountries({ 
      region: parseArrToStr(selectedFilters.region),
    });
  }, [selectedFilters.region]);

  useEffect(() => {
    fetchCities({
      region: parseArrToStr(selectedFilters.region),
      country: parseArrToStr(selectedFilters.country),
    });
  }, [selectedFilters.region, selectedFilters.country]);

  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    fetchMajors();
  }, []);

  useEffect(() => {
    fetchDegrees();
  }, []);

  useEffect(() => {
    fetchMoreInfos();
  }, []);

  useEffect(() => {
    fetchDirectoryUniversities();
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setFilterShow(false);
      setSortShow(false);
    }
  }, [windowSize]);

  if (isMobile) {
    return <MobilePlaceholder title={t("CATALOG.TITLE")}/>;
  }

  return (
    <>
      { 
        universityOpen ? 
        <UniversityDetails 
        selectedFilters={selectedFilters}
          setUniversityOpen={setUniversityOpen}
          universityId={universityId}
        /> : 
        <section className={"catalog"}>
          <div className={"catalog__header"}>
            <PageTitle title={"CATALOG.SUBTITLE"}/>
            {windowSize.width < 574 && (
              <div className={"catalog__buttons"}>
                <Button
                  className={"catalog__btn btn--pill"}
                  text={t("BUTTON.FILTERS")}
                  iconLeft={<Icon name={"filter"} />}
                  height={56}
                  onClick={() => setFilterShow(true)}
                />
                <Button
                  className={"catalog__btn catalog__btn--last btn--pill"}
                  text={t("BUTTON.SORT_BY")}
                  iconLeft={<Icon name={"sort"} />}
                  height={56}
                  onClick={() => setSortShow(true)}
                />
                {regions.length > 0 && (
                  <MobileFilter
                    tabs={tabs}
                    selectedTab={selectedTab}
                    filterShow={filterShow}
                    setFilterShow={setFilterShow}
                    debouncedValue={debouncedValue}
                    order={order}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setIsResetFilter={setIsResetFilter}
                    setSelectedOrder={setSelectedOrder}
                  />
                )}
                <MobileOrder
                  tabs={tabs}
                  selectedTab={selectedTab}
                  sortShow={sortShow}
                  setSortShow={setSortShow}
                  setOrder={setOrder}
                  selectedOrder={selectedOrder}
                  setSelectedOrder={setSelectedOrder}
                />
              </div>
            )}
          </div>
          {windowSize.width > 574 && (
            <Fragment>
              <CatalogFilter
                className={"catalog__filters"}
                tabs={tabs}
                selectedTab={selectedTab}
                debouncedValue={debouncedValue}
                order={order}
                setOrder={setOrder}
                setSearch={setSearch}
                initialFilters={initialFilters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                additionalParams={additionalParams}
                setAdditionalParams={setAdditionalParams}
                isResetFilter={isResetFilter}
                setIsResetFilter={setIsResetFilter}
              />
              <CatalogOrder
                className={"catalog__sort"}
                tabs={tabs}
                selectedTab={selectedTab}
                search={search}
                setSearch={setSearch}
                order={order}
                setOrder={setOrder}
                isResetFilter={isResetFilter}
              />
            </Fragment>
          )}
          <CatalogTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedFilters={selectedFilters}
            initialFilters={initialFilters}
            setSelectedFilters={setSelectedFilters}
            search={search}
            setSearch={setSearch}
            debouncedValue={debouncedValue}
            order={order}
            setOrder={setOrder}
            additionalParams={additionalParams}
            setIsResetFilter={setIsResetFilter}
            setSelectedOrder={setSelectedOrder}
            universityId={universityId}
            setUniversityId={setUniversityId}
            universityOpen={universityOpen}
            setUniversityOpen={setUniversityOpen}
          />
        </section>
      }
    </>
    
  );
}
