import React, { useCallback, useEffect } from "react";
import { Button, Tabs, Icon } from "@components/common";
import { MockNavNumbers } from "@components/feature";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import { WHATSAPP_MOCK_NUET, WHATSAPP_MOCK_SAT } from "@utils/consts";
import { CustomLink } from "@components/common";
import "./MockNavigation.scss";

export default function MockNavigation({
  userResponsesCount,
  mock,
  score,
  setCurrentQuestionIndex,
  currentQuestionIndex,
  stage,
  currentSection,
  handlePreviousQuestion,
  handleNextQuestion,
  questionsCount,
  userAnswers,
  questionsData,
  setCurrentSection,
  isNuet,
  handleModal,
}) {
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const isFinished = stage === "finished";

  const isQuestionAnswered = (index) => {
    if (isFinished) {
      return false;
    }
    const questionId = questionsData[index].id;
    return (
      userAnswers.hasOwnProperty(questionId) && userAnswers[questionId] != null
    );
  };

  const scoreMax = isNuet ? 240 : 1600;
  const isSATMath =
    currentSection === "module3" || currentSection === "module4";
  const isNuetMath = currentSection === "math";

  const satMaxModuleScore = 800;
  const nuetMaxModuleScore = 120;


  const nextSection = () => {
    const currentIndex = currentConfig.findIndex(
      (tab) => tab.key === currentSection
    );
    const nextIndex = (currentIndex + 1) % currentConfig.length; // Loop back to the first section if at the end
    const nextSectionKey = currentConfig[nextIndex].key;
    setCurrentSection(nextSectionKey);
  };

  const examConfig = {
    nuet: [
      { key: "math", label: "Math" },
      { key: "crit", label: "Critical Thinking" },
    ],
    sat: [
      { key: "module1", label: "Verbal 1" },
      { key: "module2", label: "Verbal 2" },
      { key: "module3", label: "Math 1" },
      { key: "module4", label: "Math 2" },
    ],
  };

  const getContactLink = (mock) => {
    const contactLinks = {
      nuet_mock: WHATSAPP_MOCK_NUET,
      sat_mock: WHATSAPP_MOCK_SAT,
    };

    return contactLinks[mock?.type] || "";
  };

  const contactLink = getContactLink(mock);

  const currentExam = isNuet ? "nuet" : "sat";
  const currentConfig = examConfig[currentExam];
  const currentConfigSection = examConfig[currentExam].find(
    (module) => module.key === `${stage}`
  );

  const handleTabSelect = useCallback(
    (key) => {
      setCurrentSection(key);
      setCurrentQuestionIndex(0);
    },
    [setCurrentSection, setCurrentQuestionIndex]
  );

  const selectedTabKey = currentConfig.find(
    (tab) => tab.key === currentSection
  )?.key;

  const selectedTabLabel =
    currentConfig.find((tab) => tab.key === selectedTabKey)?.label ||
    currentConfig[0]?.label;

  useEffect(() => {
    handleTabSelect(currentConfig[0].key);
  }, [currentExam]);

  const handleKeyPress = useCallback(
    (event) => {
      if (
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "TEXTAREA"
      ) {
        if (event.key === "ArrowLeft") {
          handlePreviousQuestion();
        } else if (event.key === "ArrowRight") {
          handleNextQuestion();
        }
      }
    },
    [handlePreviousQuestion, handleNextQuestion]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className={"mock__nav"}>
      <div className="mock__nav-up">
        {isFinished ? (
          <Tabs
            className={"mock__tabs"}
            tabClassName={"mock__tabs-tab"}
            tabs={currentConfig.map((tab) => tab.label)}
            selected={selectedTabLabel}
            setSelected={(label) => {
              const selectedTab = currentConfig.find(
                (tab) => tab.label === label
              );
              if (selectedTab) {
                handleTabSelect(selectedTab.key);
              }
            }}
          />
        ) : (
          <div className="mock__section">{currentConfigSection?.label}</div>
        )}

        <MockNavNumbers
          isQuestionAnswered={isQuestionAnswered}
          questionsCount={questionsCount}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          currentQuestionIndex={currentQuestionIndex}
        />

        <div className="mock__nav-container">
          <div className="mock__nav-buttons">
            <div className="mock__nav-buttons-results">
              {!isNuet && isFinished && score && (
                <div>
                  {isSATMath ? (
                    <div className="mock__nav-buttons-results-result">
                      <div>{`${t("MOCK.MATH")}`}</div>
                      <div className="mock__nav-buttons-results-result-number">{`${score[0]}/${satMaxModuleScore}`}</div>
                    </div>
                  ) : (
                    <div className="mock__nav-buttons-results-result">
                      <div>{`${t("MOCK.VERBAL")}`}</div>
                      <div className="mock__nav-buttons-results-result-number">{`${score[0]}/${satMaxModuleScore}`}</div>
                    </div>
                  )}
                </div>
              )}
              {isNuet && isFinished && score && (
                <div>
                  {isNuetMath ? (
                    <div className="mock__nav-buttons-results-result">
                      <div>{`Math:`}</div>
                      <div className="mock__nav-buttons-results-result-number">{`${score[0]}/${nuetMaxModuleScore}`}</div>
                    </div>
                  ) : (
                    <div className="mock__nav-buttons-results-result">
                      <div>{`Crit:`}</div>
                      <div className="mock__nav-buttons-results-result-number">{`${score[1]}/${nuetMaxModuleScore}`}</div>
                    </div>
                  )}
                </div>
              )}

              {!isFinished ? (
                <div>{`${userResponsesCount} / ${questionsData.length}`}</div>
              ) : (
                <div className="mock__nav-buttons-results-result">
                  <div>{`${t("MOCK.YOUR_SCORE")}`}</div>
                  <div className="mock__nav-buttons-results-result-number">{`${Math.round(
                    score?.reduce((acc, i) => acc + i),
                    0
                  )}/${scoreMax}`}</div>
                </div>
              )}
            </div>

            <div className="mock__nav-buttons-buttons">
              <Button
                className="mock__nav-buttons-prev"
                onClick={handlePreviousQuestion}
                disabled={currentQuestionIndex === 0}
                text={t("MOCK.PREV")}
              />

              {!isMobile && (
                <Button
                  className="mock__nav-buttons-next"
                  onClick={handleNextQuestion}
                  disabled={currentQuestionIndex === questionsCount - 1}
                  text={t("MOCK.NEXT")}
                />
              )}

              {isMobile && (
                <>
                  {currentQuestionIndex !== questionsCount - 1 ? (
                    <Button
                      className="mock__nav-buttons-next"
                      onClick={handleNextQuestion}
                      disabled={currentQuestionIndex === questionsCount - 1}
                      text={t("MOCK.NEXT")}
                    />
                  ) : !isFinished ? (
                    <Button
                      className="mock__nav-buttons-next"
                      text={t("MOCK.NEXT_SECTION")}
                      onClick={handleModal}
                    />
                  ) : (
                    <Button
                      className="mock__nav-buttons-next"
                      text={t("MOCK.NEXT_SECTION")}
                      onClick={nextSection}
                    />
                  )}
                </>
              )}
            </div>

            {isMobile && (
              <div className="mock__nav-buttons-contact">
                {isFinished && (
                  <CustomLink
                    className={"ongoing__button"}
                    isExternal={true}
                    newTab={true}
                    to={contactLink}
                  >
                    {t("MOCK.CONTACT")}
                  </CustomLink>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
