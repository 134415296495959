import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DashboardContentV2,
  DashboardMobile,
  DashboardCollectInfoModal,
} from "@components/feature";
import "./Dashboard.scss";
import { useIsMobile } from "@helpers/hooks";

export default function Dashboard() {
  const isMobile = useIsMobile();
  const { myEducation } = useSelector((state) => state.questionnaire);
  const [showCollectInfoModal, setShowCollectInfoModal] = useState(false);

  // Usually, it pops up and then disappears, because user has already filled out form.
  // So this logic helps to prevent that.
  useEffect(() => {
    if (myEducation?.current_study_year) {
      setShowCollectInfoModal(false);
    } else {
      const timer = setTimeout(() => {
        setShowCollectInfoModal(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [myEducation]);

  return (
    <>
      {/* Temporarily hided modal which collects info */}
      {showCollectInfoModal && <DashboardCollectInfoModal />}

      {isMobile ? <DashboardMobile /> : <DashboardContentV2 />}
    </>
  );
}
