import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PROGRAM_STATUS_FILTER } from "@utils/consts";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { Pagination, LayoutContainer, FormItem } from "@components/common";
import { FavoriteEmpty, FavoriteSkeleton, FavoritesTitle, FavoritesCard, MobilePlaceholder } from "@components/feature";
import "./Favorites.scss";

export default function Favorites() {
  const { t } = useTranslate(),
    isMobile = useIsMobile(),
    { favorites, isLoading, totalCountFavorites } = useSelector((state) => state.program),
    { fetchFavorites } = useAction(),
    [currentPage, setCurrentPage] = useState(1),
    [pageSize, setPageSize] = useState(10),
    [selectedUniversity, setSelectedUniversity] = useState({name: `FAVORITE.STATUS.ALL`, value: "All"}),
    [selectedStatus, setSelectedStatus] = useState({name: `FAVORITE.STATUS.ALL`, value: "All"}),
    subjectUniversities = useMemo(
      () =>
        favorites
          ? [
              { id: -1, value: "All", name: t("APPLICATION.ALL") },
              ...[...new Set(favorites.map((obj) => obj.university))].map((university, index) => ({
                id: index,
                value: university,
                name: university,
              })),
            ]
          : [],
      [t, favorites]
    ),
    offset = (currentPage - 1) * pageSize,
    columns = useMemo(
      () => [
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_3") },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_8") },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_9") },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_10") },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_11") },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_6") },
        { name: t("RECOMMENDATIONS.MAJOR.LABEL_12") },
        { name: t("FAVORITE.STATUS.TITLE") },
      ],
      [t]
    );

  const universityInfo = useMemo(() => {
    return {
      header: [
        {
          id: 1,
          label: t("FAVORITE.STATUS.TITLE"),
          field: "dropdown",
          name: "status",
          value: selectedStatus,
          defaultValue: {
            name: `FAVORITE.STATUS.${selectedStatus.value.toUpperCase()}`,
            value: selectedStatus.value,
          },
          options: PROGRAM_STATUS_FILTER(t),
        },
        {
          id: 2,
          label: t("FAVORITE.UNIVERSITY"),
          field: "dropdown",
          name: "university",
          value: selectedUniversity,
          defaultValue: {
            name: selectedUniversity.value === "All" ? `FAVORITE.STATUS.ALL` : selectedUniversity.name,
            value: selectedUniversity.value,
          },
          options: subjectUniversities,
        },
      ],
    };
  }, [subjectUniversities, t, selectedUniversity, selectedStatus, PROGRAM_STATUS_FILTER(t)]);

  const handleChange = async (e, field, name) => {
    if (name === "status") {
      await setSelectedStatus(e);
    }
    if (name === "university") {
      await setSelectedUniversity(e);
    }
  };
  
  const handleChangePageSize = async (pageSize) => {
    setPageSize(pageSize);
    await getFavorites(pageSize);
  };

  const getFavorites = async (limit = 10, offset = 0) => {
    await fetchFavorites({ limit, offset, is_shortlisted: true });
  };

  useEffect(() => {
    async function fetchData() {
      await getFavorites(pageSize, offset);
    }
    return fetchData();
  }, [offset, t]);

  if (isMobile) {
    return <MobilePlaceholder title={t("FAVORITE.TITLE")}/>;
  }

  if (favorites?.length === 0 && !isLoading) {
    return <FavoriteEmpty title={t("FAVORITE.TITLE")} text={t("FAVORITE.EMPTY")} />;
  }

  return (
    <section className={"favorites"}>
      {favorites ? (
        <LayoutContainer className={"layout__equal-padding"}>
          <FavoritesTitle />
          <div className="favorites__header">
            {universityInfo.header.map((item) => (
              <FormItem key={item.id} item={item} handleChange={handleChange} />
            ))}
          </div>
          <div className="favorites__header-items">
            {columns.map((column) => (
              <p
                className={
                  column.name == t("RECOMMENDATIONS.MAJOR.LABEL_3")
                    ? "favorites__header-group"
                    : "favorites__header-item"
                }
                key={column.name}
              >
                {column.name}{" "}
              </p>
            ))}
          </div>
          {favorites
            .filter(
              (univer) =>
                (!selectedUniversity ||
                  selectedUniversity.value === "All" ||
                  univer.university === selectedUniversity.value) &&
                (!selectedStatus ||
                  selectedStatus.value === "All" ||
                  (selectedStatus.value === "Applying" && univer.is_applying) ||
                  (selectedStatus.value === "Saved" && !univer.is_applying))
            )
            .map((univer) => (
              <div key={univer.id}>
                <FavoritesCard key={univer.id} item={univer} />
              </div>
            ))}
        </LayoutContainer>
      ) : (
        <FavoriteSkeleton count={10} />
      )}
      <Pagination
        className={"favorites__pagination"}
        totalCount={totalCountFavorites}
        pageSize={pageSize}
        currentPage={currentPage}
        siblingCount={0}
        onPageChange={(page) => setCurrentPage(page)}
        onPageSizeChange={handleChangePageSize}
      />
    </section>
  );
}
