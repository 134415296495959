import React, { useEffect, useState, useRef } from "react";
import { formatTime } from "@utils/functions";
import { useSelector } from "react-redux";
import "./MockTimer.scss";

const WARNING_TIME = 60000;

export default function MockTimer({handleSubmit}) {
  const {
    stage,
    // NUET
    mathEndTime,
    critEndTime,
    // SAT
    verbalOneEndTime,
    verbalTwoEndTime,
    mathOneEndTime,
    mathTwoEndTime,
    // IELTS
    readingEndTime,
    listeningEndTime,
    speakingEndTime,
    writingEndTime,
  } = useSelector((state) => state.mocks);
  const [timeLeft, setTimeLeft] = useState(null);

  const hasSubmittedRefs = useRef({
    math: false,
    crit: false,
    module1: false,
    module2: false,
    module3: false,
    module4: false,
    listening: false,
    reading: false,
    writing: false,
    speaking: false,
  });
  
  // const handleTimeUpBanner = () => {
  //   setIsTimeUpBannerOpen(true);
  // };

  useEffect(() => {
    let timer;

    const endTimeMappings = {
      // NUET
      math: mathEndTime,
      crit: critEndTime,
      // SAT
      module1: verbalOneEndTime,
      module2: verbalTwoEndTime,
      module3: mathOneEndTime,
      module4: mathTwoEndTime,
      // IELTS
      listening: listeningEndTime,
      reading: readingEndTime,
      writing: writingEndTime,
      speaking: speakingEndTime,
    };

    if (stage !== "finished") {
      const endTime = endTimeMappings[stage];
      const end = new Date(endTime).getTime();

      const updateRemainingTime = () => {
        const now = new Date().getTime();
        const distance = end - now;
        if (distance <= 0 && !hasSubmittedRefs.current[stage]) {
          clearInterval(timer);

          // If it fails, user will see that nothing happens (e.g. no redirect to next section or no popup)
          // and can submit manually
          // Upd: If handleSubmit fails on mobile, user can only submit manually if they are on the last question in a section.
          handleSubmit();

          hasSubmittedRefs.current[stage] = true;
        } else {
          setTimeLeft(Math.max(distance, 0));
        }
      };

      updateRemainingTime();
      timer = setInterval(updateRemainingTime, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [stage]);

  return (
    <div>
      <p
        className={`timer__numbers ${
          stage === "finished"
            ? "timer__blue"
            : timeLeft <= WARNING_TIME
            ? "timer__red"
            : "timer__green"
        }`}
      >
        {stage === "finished" ? "Review" : formatTime(timeLeft)}
      </p>
    </div>
  );
}
