import { logout } from '@utils/functions';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { MOCK_EXAMS, SCHOLARSHIPS_ROUTE, STP_OPEN_SIDE } from '@utils/consts';
import { useTranslate, useWindowSize } from "@helpers/hooks";
import { List } from '@components/common';
import { MobileNavItem } from '@components/feature';
import './More.scss';

const More = () => {
    const {t} = useTranslate(),
    history = useHistory(),
    windowSize = useWindowSize(),
    MOBILE_ROUTES = [
        {
            id: 1,
            icon: "university_shortlist-admissionRoadmap",
            route: "https://prep.dev-smartestprep.com/",
            title: t("MOBILE.UBT_TEST"),
            isExternal: true,
            target: "_blank"
        },
        {
            id: 4,
            icon: "heart",
            route: "/recommendations",
            title: t("RECOMMENDATIONS.TITLE"),
        },
        {
            id: 5,
            icon: "university-program",
            route: "/catalog",
            title: t("CATALOG.TITLE"),
        },
        {
            id: 15,
            route: SCHOLARSHIPS_ROUTE,
            icon: "scholarships",
            title: t("SCHOLARSHIPS.TITLE"),
        },
        {
            id: 6,
            icon: "saved",
            route: "/favorites",
            title: t("FAVORITE.TITLE"),
        },
        {
            id: 7,
            icon: "house",
            route: "/application",
            title: t("APPLICATION.TITLE"),
        },
        {
            id: 11,
            icon: "mock-sidebar",
            route: MOCK_EXAMS,
            title: t("MOCK.TITLE"),
        },
        {
            id: 8,
            icon: "writing-builder",
            route: "/essay",
            title: t("ESSAY.TITLE"),
        },
        {
            id: 10,
            icon: "logout",
            route: `${STP_OPEN_SIDE}/auth/`,
            title: t("HEADER.PROFILE.LOGOUT"),
            isExternal: true,
            handleClick: logout,
        },
    ]

    useEffect(() => {
        if (windowSize.width > 481) history.push("/");
    }, [windowSize]);

    return (
        <div className="more">
            <h2 className="more__title">{t("MOBILE.OTHER_SERVICES")}</h2>
            <List
                items={MOBILE_ROUTES}
                className="more__list"
                renderItem={({ id, icon, route, title, isExternal, target, handleClick }) => (
                    <MobileNavItem key={id} icon={icon} route={route} title={title} isExternal={isExternal} target={target} handleClick={handleClick} />
                )}
            />
        </div>
    );
}

export default More;
