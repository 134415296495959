import { Icon } from "@components/common";
import React, { useState, useEffect } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useAction, useTranslate } from "@helpers/hooks";
import { AudioPlayer } from "@components/feature";

import { useSelector } from "react-redux";
import { blobToBase64 } from "@utils/functions";
import "./IeltsVoiceRecord.scss";

export default function IeltsVoiceRecord({
  userAnswer,
  onAnswerChange,
  question,
}) {
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [startAgainClicked, setStartAgainClicked] = useState(false);
  const { t } = useTranslate();
  const { submitSpeakingResponse } = useAction();
  const { mock } = useSelector((state) => state.mocks);

  const mockId = mock?.ielts_mock_exam?.id;

  const addAudioElement = async (blob) => {
    setAudioBlob(blob);
    setStartAgainClicked(false);
    const base64Audio = await blobToBase64(blob);
    onAnswerChange(base64Audio);

    try {
      submitSpeakingResponse({
        id: mockId,
        data: {
          audio_answer: base64Audio,
          ielts_mock_speaking_question_id: parseInt(question.id, 10),
        },
      });
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  useEffect(() => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);
    }
  }, [audioBlob]);

  const recorderControls = useAudioRecorder();

  const handleRecordClick = () => {
    if (isRecording) {
      recorderControls.stopRecording();
    } else {
      recorderControls.startRecording();
      audioURL && setAudioURL(null);
    }
    setIsRecording(!isRecording);
  };

  // const handleStartAgain = () => {
  //   setAudioURL(null);
  //   setStartAgainClicked(true);
  // };

  return (
    <div className="audio">
      <div className={`audio__container`}>
        {!isRecording &&
          !audioURL &&
          !question?.question_speaking?.response && (
            <div className={`audio__icon`}>
              <Icon name={"mic"} handleClick={handleRecordClick} />
            </div>
          )}
        {isRecording && (
          <div className={`audio__icon`}>
            <Icon name={"pause"} handleClick={() => handleRecordClick()} />
          </div>
        )}
        {userAnswer ? (
          <AudioPlayer src={userAnswer} />
        ) : question?.question_speaking?.response ? (
          <AudioPlayer src={question?.question_speaking?.response} />
        ) : audioURL && !isRecording && !startAgainClicked ? (
          <AudioPlayer src={audioURL} />
        ) : (
          <div className={` ${isRecording && "audio__group"}`}>
            <AudioRecorder
              onRecordingComplete={addAudioElement}
              recorderControls={recorderControls}
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
              }}
              onNotAllowedOrFound={(err) => console.table(err)}
              downloadFileExtension="mp3"
              showVisualizer={true}
            />
          </div>
        )}
      </div>

      <p className="audio__warning">{t("MOCK.REPLAY")}</p>
    </div>
  );
}
