import React, { useState, useEffect } from "react";
import { Icon, ButtonLink, Button } from "@components/common";
import {
  getEarnedCoins,
  getNextLessonLink,
  notify,
  showQuote,
} from "@utils/functions";
import { useTranslate } from "@helpers/hooks";
import { useSelector } from "react-redux";
import { useAction } from "@helpers/hooks";
import { useIsMobile } from "@helpers/hooks";
import { startCoinAnimation } from "@utils/functions";
import { Feedback } from "@components/feature/Feedback";
import { toast } from "react-toastify";
import { quotes } from "@utils/consts";
import "./LessonCompletion.scss";

export default function LessonCompletion({
  courseId,
  sectionId,
  lessonId,
  lesson,
}) {
  const { t, i18n } = useTranslate();
  const { course, lessonEarnedCoins } = useSelector((state) => state.courses);
  const { changeLessonStatus, fetchCourses, fetchBadge } = useAction();
  const isMobile = useIsMobile();
  const [showFeedback, setShowFeedback] = useState(false);
  const [showCoins, setShowCoins] = useState(false);

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (lesson.completed) {
      fetchBadge();
    }
  }, [lesson.completed]);

  const onCompletedClick = async (lessonId) => {
    // Initialize a flag to check if feedback has been shown
    let feedbackShown = false;

    if (lesson.type === "video") {
      const feedbackChance = Math.floor(Math.random() * 15) + 1;
      if (feedbackChance === 1) {
        setShowFeedback(true);
        feedbackShown = true;
      }
    }
    showQuote(3);
    try {
      await changeLessonStatus(lessonId);
      setShowCoins(true);
      /*
       * The showCoins flag ensures coins are only displayed once when earned.
       * Without this flag, coins would show every time lessonEarnedCoins exists in state,
       * including on component re-renders. Instead, we only want to show them
       * immediately after the backend confirms the lesson completion.
       */
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (lessonEarnedCoins && showCoins) {
      const language = i18n.language;
      const coinsText = getEarnedCoins(language, lessonEarnedCoins);
      notify(coinsText, "success", true);
      startCoinAnimation(5000);
      setShowCoins(false);
      /*
       * Now the showCoins flag is set to false, so the coins will not show again
       * unless the lessonEarnedCoins state changes.
       */
    }
  }, [lessonEarnedCoins, showCoins]);

  return (
    <div>
      {lesson.completed ? (
        <div
          className={
            isMobile ? "lesson__button-group-mob" : "lesson__button-group"
          }
        >
          <div className="lesson__button-group__completed">
            <Icon name={"completed-lesson"} className="icon--completed" />
            <p>{t("LMS.BUTTON.COMPLETED")}</p>
          </div>
          <ButtonLink
            link={getNextLessonLink(courseId, sectionId, lessonId, course)}
            text={t("LMS.BUTTON.GO_NEXT_ITEM")}
            className={isMobile ? "lesson__button-mob" : "lesson__button"}
          />
        </div>
      ) : (
        <Button
          onClick={() => onCompletedClick(lesson.id)}
          text={t("LMS.BUTTON.MARK_COMPLETED")}
          className={isMobile ? "lesson__button-mob" : "lesson__button"}
        />
      )}
      {showFeedback && <Feedback handleClose={() => setShowFeedback(false)} />}
    </div>
  );
}
