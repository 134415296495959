// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings__title {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Settings.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ","sourcesContent":["@import \"src/styles/variables\";\n\n.settings {\n  &__title {\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
