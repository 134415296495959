import React, { useState } from "react";
import { Button, NewModalLayout } from "@components/common";
import { useIsMobile, useTranslate } from "@helpers/hooks";
import "./MockCard.scss";

const MockCard = ({ item, handleStart, handleBuy }) => {
  const { t } = useTranslate();
  const examKey = `${item.type}_exam`;
  const discountedPrice = 10000;
  const isMobile = useIsMobile();
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const maxScore =
    item.type === "ielts_mock" ? "9.0" : item.type === "nuet_mock" ? 240 : 1600;

  const calculateDiscountedPrice = (sum) =>
    ((sum / discountedPrice - 1) * 100).toFixed(0);

  const handleButtonClick = () => {
    if (item.type === "ielts_mock" && isMobile) {
      setIsMobileModalOpen(true);
    } else if (item.access) {
      handleStart(item);
    } else {
      handleBuy(item);
    }
  };

  return (
    <li className={"mock__card"}>
      <div className="mock__card_text">
        <div className="mock__card_text_nums">
          <h4 className="mock__card-title">{item.title}</h4>
          {item[examKey]?.completed ? (
            <p className="mock__card_text_nums-nums-score">
              Score: {item[examKey].score}/{maxScore}
            </p>
          ) : (
            !item.access && (
              <div className="mock__card_text_nums-nums">
                <p className="mock__card_text_nums-nums-original">
                  {discountedPrice}
                </p>
                <p className="mock__card_text_nums-nums-discount">{item.sum}</p>
              </div>
            )
          )}
        </div>
        {!item.access && (
          <div className="mock__card_text_circle">
            <div className="mock__card_circle">
              {calculateDiscountedPrice(item.sum)}%
            </div>
          </div>
        )}
      </div>

      <div className="mock__card_text__buttons">
        <Button
          className={
            item[examKey]?.completed
              ? "mock__card_text__buttons_widy card__button"
              : "card__button card__button-primary card__buttons-width"
          }
          text={
            item[examKey]?.completed
              ? t("MOCK.FEEDBACK")
              : item.access
              ? t("MOCK.START_EXAM")
              : t("BUTTON.BUY")
          }
          onClick={handleButtonClick}
        />
      </div>
      {isMobileModalOpen && (
        <NewModalLayout className="level-up">
          <div className="mock__card-warning">
            <h2 className="mock__card-warning-title">
              {t("MOCK.MOBILE_WARNING")}
            </h2>
            <Button
              text={t("MOCK.MOBILE_WARNING_BUTTON")}
              className="btn--secondary btn--pill level-up__btn"
              onClick={() => setIsMobileModalOpen(false)}
            />
          </div>
        </NewModalLayout>
      )}
    </li>
  );
};

export default MockCard;
