import { Button, Image } from "@components/common";
import { useTranslate } from "@helpers/hooks";
import "./ProfileSupport.scss";

export default function ProfileSupport({ handleClick }) {
  const { t } = useTranslate();

  return (
    <div className="profile__support">
      <div className="profile__support-consult">
        <h3 className={`profile__support-title`}>
          {t("SUPPORT.TITLE")}
        </h3>
        <p className={`profile__support-subtitle`}>
          {t("SUPPORT.DESCRIPTION")}
        </p>
      </div>
      <div>
        <Button
          text={t("SUPPORT.MORE")}
          className="profile__support-button"
          onClick={handleClick}
        />
      </div>
      <Image
        name="support_bell.png"
        alt="support bell"
        className={"profile__support-bell"}
      />
    </div>
  );
}
