import { createSlice } from "@reduxjs/toolkit";

import { getOrCreateReferralCode, activateReferralCode, getReferralCodeActivated } from "@store/actions/referralAction";

const initialState = {
    myReferralCode: null,
    isLoading: false,
    // shows if the user activated somebody else's referral code
    isReferralCodeActivated: false,
    activatedReferralCode: null,
};

const referralSlice = createSlice({
    name: "referral",
    initialState,
    reducers: {},
    extraReducers: {
        [getOrCreateReferralCode.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getOrCreateReferralCode.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.myReferralCode = action.payload.code;
        },
        [getOrCreateReferralCode.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [activateReferralCode.pending.type]: (state) => {
            state.isLoading = true;
        },
        [activateReferralCode.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isReferralCodeActivated = true;
            state.activatedReferralCode = action.payload;
        },
        [activateReferralCode.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [getReferralCodeActivated.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getReferralCodeActivated.fulfilled.type]: (state, action) => {
            state.isLoading = false;
            state.isReferralCodeActivated = action.payload.is_activated;
            if (action.payload.is_activated) {
                state.activatedReferralCode = action.payload.data;
            }
        },
        [getReferralCodeActivated.rejected.type]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export default referralSlice.reducer;
