// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .feedback-container {
    padding: 0px;
  }
}
.feedback-container__text {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.feedback-container__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  padding: 20px;
}
.feedback-container__success h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 16px;
}
.feedback-container__success p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
}
.feedback-container form {
  display: flex;
  flex-direction: column;
}
.feedback-container p {
  font-size: 18px;
}

.feedback-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.feedback-container__header h2 {
  font-size: 24px;
}

.feedback_modal {
  padding: 20px;
  min-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}
@media (max-width: 768px) {
  .feedback_modal {
    min-height: 100dvh !important;
    min-width: 100vw !important;
    border-radius: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Feedback/Feedback.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;AADF;AAGE;EANF;IAOI,YAAA;EAAF;AACF;AAEE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,aAAA;AADJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AAAN;AAEI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAAN;AAIE;EACE,aAAA;EACA,sBAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;;AAOA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAJF;AAKE;EACE,eAAA;AAHJ;;AAOA;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AAJF;AAME;EANF;IAOI,6BAAA;IACA,2BAAA;IACA,2BAAA;EAHF;AACF","sourcesContent":["@import \"@styles/variables\";\n\n.feedback-container {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n  overflow-y: auto;\n\n  @media (max-width: 768px) {\n    padding: 0px;\n  }\n\n  &__text {\n    text-align: center;\n    font-size: 24px;\n    font-weight: 600;\n  }\n\n  &__success {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-width: 400px;\n    padding: 20px;\n    & h4 {\n      font-size: 24px;\n      font-weight: 600;\n      margin-bottom: 16px;\n      margin-top: 16px;\n    }\n    & p {\n      font-size: 18px;\n      font-weight: 400;\n      margin-bottom: 16px;\n    }\n  }\n\n  form {\n    display: flex;\n    flex-direction: column;\n  }\n\n  p {\n    font-size: 18px;\n  }\n}\n\n.feedback-container__header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n  h2 {\n    font-size: 24px;\n  }\n}\n\n.feedback_modal {\n  padding: 20px;\n  min-width: 500px;\n  max-height: 90vh;\n  overflow-y: auto;\n\n  @media (max-width: 768px) {\n    min-height: 100dvh !important;\n    min-width: 100vw !important;\n    border-radius: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
