import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAction, useTranslate, useIsMobile } from "@helpers/hooks";
import { Button, Input, BtnLoader, Icon } from "@components/common";
import "./ReferralActivationCard.scss";

const ReferralActivationCard = ({ className }) => {
  const isMobile = useIsMobile();
  const { isReferralCodeActivated, activatedReferralCode, isLoading } = useSelector((state) => state.referral);
  const { activateReferralCode, getReferralCodeActivated } = useAction();
  const [referralCode, setReferralCode] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getReferralCodeActivated();
  }, []);

  const handleActivateReferralCode = () => {
    activateReferralCode({ code: referralCode });
  };

  if (isMobile) {
    return (
      <div className={`referral-activation-card mobile ${className}`}>
        {isReferralCodeActivated ? (
          <div className="promo-code-container">
            <div className="promo-code-header" onClick={() => setIsExpanded(!isExpanded)}>
              <p>Ваш промокод</p>
            </div>
            <Input
              inputClassName="referral-input promo-code-input"
              type="text"
              value={activatedReferralCode.code}
              disabled={true}
              />
          </div>
        ) : (
          <div>
            <div className="promo-code-header" onClick={() => setIsExpanded(!isExpanded)}>
              <p>Есть промокод?</p>
              <Icon className={`arrow ${isExpanded ? 'up' : 'down'}`} name="arrow-up" />
            </div>
            {isExpanded && (
              <div className="input-container">
                <Input
                  placeholder="Введите промокод"
                  inputClassName="referral-input"
                  type="text"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                />
                {isLoading ? (
                  <BtnLoader className="btn-loader--blue" />
                ) : (
                  <button 
                    className="tick-button" 
                    onClick={handleActivateReferralCode}
                    disabled={!referralCode}
                  >
                    <Icon name="tick-point-medium" />
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  // Desktop version remains unchanged
  return (
    <div className={`referral-activation-card ${className}`}>
      {isReferralCodeActivated ? (
        <div className="promo-code-container">
          <p>Активный промокод</p>
          <Input
            inputClassName="referral-input promo-code-input"
            type="text"
            value={activatedReferralCode.code}
            disabled={true}
          />
        </div>
      ) : (
        <div>
          <p>Есть промокод?</p>
          <Input
            placeholder="Введите промокод"
            inputClassName="referral-input"
            type="text"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
          {isLoading ? (
            <BtnLoader className="btn-loader--blue" />
          ) : (
            <Button
              className="referral-activation-card__button referral-activation-card__button-primary"
              onClick={handleActivateReferralCode}
              text="Активировать"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ReferralActivationCard;
