import { ReferralService } from "@api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { notify, findError } from "@utils/functions";

const getOrCreateReferralCode = createAsyncThunk("referral/getOrCreateReferralCode", async (thunkAPI) => {
  try {
    const res = await ReferralService.getOrCreateReferralCode();
    return res;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const activateReferralCode = createAsyncThunk("referral/activateReferralCode", async (data, thunkAPI) => {
  try {
    const res = await ReferralService.activateReferralCode(data);
    return res;
  } catch (e) {
    notify(findError(e.response.data), "error");
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const getReferralCodeActivated = createAsyncThunk("referral/getReferralCodeActivated", async (thunkAPI) => {
  try {
    const response = await ReferralService.getReferralCodeActivated();
    const result = {
      is_activated: true,
      data: response,
    };
    return result;
  } catch (error) {
    if (error.response?.status === 404) {
      return { is_activated: false };
    }
    return thunkAPI.rejectWithValue(error);
  }
});

export { getOrCreateReferralCode, activateReferralCode, getReferralCodeActivated };
