import { useTranslate, useIsMobile, useAction } from "@helpers/hooks";
import { Icon, List, PageTitle, NewModalLayout } from "@components/common";
import "./Resources.scss";
import { BlogArticlesList } from "@components/feature";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { UniversityDetailsTabs } from "@components/feature";
import { PdfWindow } from "@components/common";
export default function Resources() {
    const { t } = useTranslate();
    const isMobile = useIsMobile();

    const {resources, isResourcesLoading} = useSelector((state) => state.blog);
    const {fetchResources, fetchBlogArticles} = useAction();
    const [currentTab, setCurrentTab] = useState("Resources");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [currentFile, setCurrentFile] = useState(null);

    const uniqueCategories = [...new Set(resources?.map(resource => resource.category.name))];
    

    useEffect(() => {
        fetchResources();
        fetchBlogArticles();
    }, [])
    
    const openPdfModal = (resource) => {
        setPdfUrl(resource.file);
        setIsModalOpen(true);
        setCurrentFile(resource.title)
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setPdfUrl('');
    };

    const getContent = (tabName) => {
        const selectedTab = resources.map(resource => resource.category.name === tabName);
        if (!selectedTab) return null;

        return (
            <div className="resources__files">
                {tabName !== "Resources" && (
                <div>
                    <p>{tabName}</p>
                    {resources
                        .filter(resource => resource.category.name === tabName)
                        .map(resource => (
                            <a key={resource.id} onClick={() => openPdfModal(resource)} className="resources__item">
                                {t(resource.title)}
                            </a>
                        ))}
                </div>)}
            </div>
        );
    };

    return (
        <>
        <section className="resources">
            <div className="resources__title">
                <PageTitle title={t('RESOURCE.TITLE')} isGoBack />
            </div>
            <UniversityDetailsTabs
                classNameTabs="resources__sections"
                classNameTab="resources__section"
                tabs={uniqueCategories}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            {getContent(currentTab)}
            <BlogArticlesList />
        </section>
        
        
        {isModalOpen &&
        
                <NewModalLayout className="PDF">
                    <div className="PDF__close">
                        <p>{currentFile}</p>  
                        <Icon
                            name="close-rounded"
                            className="lms-badge-profile__close"
                            handleClick={closeModal}
                        />
                    </div>
                    <iframe className = "PDF__content" src={pdfUrl} />
                </NewModalLayout>
            
    
        }
        </>
    );
}
