const getEarnedCoins = (language, coins) => {
  if (language === "en") {
    return `You earned ${coins} STP ${coins > 1 ? "Coins" : "Coin"}!`;
  } else if (language === "ru") {
    return `Вы заработали ${coins} STP ${coins > 1 ? "монет" : "монету"}!`;
  } else if (language === "kz") {
    return `Сіз ${coins} STP монета таптыңыз!`;
  } else if (language === "uz") {
    return `Siz ${coins} STP tanga oldingiz!`;
  } else {
    return `You earned ${coins} STP ${coins > 1 ? "Coins" : "Coin"}!`;
  }
};

export default getEarnedCoins;
