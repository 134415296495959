import { useTranslate } from "@helpers/hooks";
import "./MobileNavigationInstructions.scss";

const MobileNavigationInstructions = () => {
  const { t } = useTranslate();

  return (
     <div className="mobile-navigation-instructions">
        <div>
          <h4 className="step__title-mobile">{t("ONBOARDING.MOBILE_NAVIGATION_INSTRUCTIONS.TITLE")}</h4>
          <p className="step__description">{t("ONBOARDING.MOBILE_NAVIGATION_INSTRUCTIONS.DESCRIPTION")}</p>
        </div>
      </div>
  );
};

export default MobileNavigationInstructions;
