// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content__pdf {
  height: 600px;
}
.content__image {
  width: 100%;
  max-width: 750px;
}
.content__group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (max-width: 481px) {
  .content__pdf {
    height: 400px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/Lesson/LessonContent/LessonContent.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;EACE,WAAA;EACA,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFJ;;AAMA;EAEI;IACE,aAAA;EAJJ;AACF","sourcesContent":[".content {\n  &__pdf {\n    height: 600px;\n  }\n\n  &__image {\n    width: 100%;\n    max-width: 750px;\n  }\n\n  &__group {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n\n@media screen and (max-width: 481px) {\n  .content {\n    &__pdf {\n      height: 400px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
