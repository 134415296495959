import { BtnLoader } from "@components/common";
import { useAction, useIsMobile, useTranslate } from "@helpers/hooks";
import { formatData } from "@utils/functions";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./DashboardPerformance.scss";

export default function DashboardPerformance() {
  const { t } = useTranslate();
  const { fetchPerformanceAndMockGrades } = useAction();
  const {
    performance,
    isPerformanceLoading,
    ieltsMockGrades,
    satMockGrades,
    nuetMockGrades,
  } = useSelector((state) => state.profile);

  const isMobile = useIsMobile();

  useEffect(() => {
    fetchPerformanceAndMockGrades();
  }, []);

  return (
    <div className="performance">
      {isPerformanceLoading ? (
        <BtnLoader className={"btn-loader--primary"} />
      ) : (
        <div className="performance__container">
          {performance && (
            <div>
              <h2 className="dashboard__subheading">
                {t("PERFORMANCE.COMMENTS_SUBTITLE")}
              </h2>
              <div className="performance__table">
                {!isMobile && (
                  <div className="performance__header">
                    <div className="performance__row">
                      <div className="performance__cell">
                        {t("PERFORMANCE.COMMENT")}
                      </div>
                      <div className="performance__cell">
                        {t("PERFORMANCE.DATE")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="performance__body">
                  {performance.map((item) => (
                    <div className="performance__row" key={item.id}>
                      <div className="performance__cell performance__newline">
                        {item?.comment}
                      </div>
                      <div className="performance__cell performance__cell--date">
                        {formatData(item?.datetime, 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {ieltsMockGrades && (
            <div>
              <h2 className="dashboard__subheading">
                IELTS mock {t("PERFORMANCE.GRADES")}
              </h2>
              <div className="performance__table ">
                {!isMobile && (
                  <div className="performance__header">
                    <div className="mock__row">
                      <div className="performance__cell mock__cell">Mock</div>
                      <div className="performance__cell mock__cell">
                        Reading
                      </div>
                      <div className="performance__cell mock__cell">
                        Listening
                      </div>
                      <div className="performance__cell mock__cell">
                        Speaking
                      </div>
                      <div className="performance__cell mock__cell">
                        Writing
                      </div>
                      <div className="performance__cell mock__cell">Total</div>
                      <div className="performance__cell mock__cell">
                        {" "}
                        {t("PERFORMANCE.DATE")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="performance__body">
                  {ieltsMockGrades.map((item) => (
                    <div className="mock__row" key={item.id}>
                      <div className="performance__cell mock__cell">
                        {item?.mock_title}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Reading"} {item?.reading_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Listening"} {item?.listening_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Speaking"} {item?.speaking_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Writing"} {item?.writing_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Total"} {item?.total_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {formatData(item?.created_at, 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {nuetMockGrades && (
            <div>
              {" "}
              <h2 className="dashboard__subheading">
                NUET mock {t("PERFORMANCE.GRADES")}
              </h2>
              <div className="performance__table ">
                {!isMobile && (
                  <div className="performance__header">
                    <div className="mock__row">
                      <div className="performance__cell mock__cell">Mock</div>
                      <div className="performance__cell mock__cell">
                        Critical Thinking
                      </div>
                      <div className="performance__cell mock__cell">
                        Mathematics
                      </div>
                      <div className="performance__cell mock__cell">Total</div>
                      <div className="performance__cell mock__cell">
                        {" "}
                        {t("PERFORMANCE.DATE")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="performance__body">
                  {nuetMockGrades.map((item) => (
                    <div className="mock__row" key={item.id}>
                      <div className="performance__cell mock__cell ">
                        {item?.mock_title}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Critical Thinking"} {item?.crit_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Mathematics"} {item?.math_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Total"}{" "}
                        {item?.crit_score + item?.math_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {formatData(item?.created_at, 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {satMockGrades && (
            <div>
              {" "}
              <h2 className="dashboard__subheading">
                SAT mock {t("PERFORMANCE.GRADES")}
              </h2>
              <div className="performance__table ">
                {!isMobile && (
                  <div className="performance__header">
                    <div className="mock__row">
                      <div className="performance__cell mock__cell">Mock</div>
                      <div className="performance__cell mock__cell">Verbal</div>
                      <div className="performance__cell mock__cell">
                        Mathematics
                      </div>
                      <div className="performance__cell mock__cell">Total</div>
                      <div className="performance__cell mock__cell">
                        {" "}
                        {t("PERFORMANCE.DATE")}
                      </div>
                    </div>
                  </div>
                )}
                <div className="performance__body">
                  {satMockGrades.map((item) => (
                    <div className="mock__row" key={item.id}>
                      <div className="performance__cell mock__cell">
                        {item?.mock_title}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Verbal"} {item?.total_verbal_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Math"} {item?.total_math_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {isMobile && "Total"}{" "}
                        {item?.total_verbal_score + item?.total_math_score}
                      </div>
                      <div className="performance__cell mock__cell">
                        {formatData(item?.created_at, 2)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
