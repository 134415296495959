import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { store } from "@store";
import { WithTranslations } from "@helpers/hoc";
import { App } from "@components/feature";
import { env } from "../../configs";

import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "@styles/index.scss";

import * as Sentry from "@sentry/react";
import { BASE_URL } from "../../configs";
import { FallbackError } from "@components/common";

Sentry.init({
  dsn:
    env === "production"
      ? "https://23e93e5ab60e1c92a9c033c2d6d66b4c@o4508640192757760.ingest.de.sentry.io/4508640204947536"
      : "https://e708a89645638e05cc81aac19c54edc5@o4508640192757760.ingest.de.sentry.io/4508640201932880",
  integrations: [
    
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      messagePlaceholder:
        "Пожалуйста, опишите проблему, c которой вы столкнулись",
      messageLabel: "Описание проблемы",
      nameLabel: "Имя",
      emailLabel: "Почта",
      submitButtonLabel: "Сообщить об ошибке",
      formTitle: "Отправка отзыва",
      namePlaceholder: "Ваше имя",
      emailPlaceholder: "Ваша почта",
      closeButtonLabel: "Закрыть",

      autoInject: false,
    }),
  ],

  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 1.0,
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
  beforeSend(event) {
    if (event.request) {
      delete event.request.headers;
    }
    return event;
  },
  sendDefaultPii: true,
  replaysOptions: {
    maskAllText: false,
    blockAllMedia: false,
  },
  tracePropagationTargets: [
    "localhost",
    new RegExp(`^${BASE_URL.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")}\\/api`),
  ],
});

export default function AppContainer() {
  return (
    <Sentry.ErrorBoundary
      onError={() => {
        window.location.href = "/error";
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ToastContainer />
          <WithTranslations>
            <Suspense fallback={"loading..."}>
              <App />
            </Suspense>
          </WithTranslations>
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}
