import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAction, useTranslate } from "@helpers/hooks";
import { WorkshopContent } from "@components/feature";

const Workshop = () => {
    const { workshopId } = useParams(),
    { fetchWorkshop } = useAction(),
    { workshop } = useSelector((state) => state.courses);
    const {t} = useTranslate();

    useEffect(() => {
        if (workshopId) fetchWorkshop(workshopId);
    }, [workshopId]);

    if (!workshopId) {
        return <h3>{t("LMS.NO_UPCOMING_WORKSHOPS")}</h3>;
    }

    return (
        <WorkshopContent workshop={workshop} workshopId={workshopId}/>
    )
}

export default Workshop;
