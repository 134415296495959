import {
  FAVORITES_ROUTE,
  PROFILE_ROUTE,
  CATALOG_ROUTE,
  LEARNING_PLATFORM_ROUTE,
  RECOMMENDATIONS_ROUTE,
  ESSAY_ROUTE,
  RESOURCES_ROUTE,
  SCHOLARSHIPS_ROUTE,
  MOCK_EXAMS,
} from "@utils/consts";

const getNavigationData = (t, totalCountFavorites, isNewFeature) => [
  {
    id: 4,
    route: PROFILE_ROUTE,
    icon: "profile-v2",
    name: t("PROFILE.TITLE"),
  },
  {
    id: 12,
    route: LEARNING_PLATFORM_ROUTE,
    icon: "learning-platform-outlined",
    name: t("LEARNING_PLATFORM.TITLE"),
  },
  {
    id: 16,
    route: MOCK_EXAMS,
    icon: "mock-sidebar",
    name: t("MOCK.TITLE"),
  },
  {
    id: 10,
    route: ESSAY_ROUTE,
    icon: "writing-builder",
    name: t("ESSAY.TITLE"),
    isDivider: true,
  },

  {
    id: 20,
    name: t("MOBILE.OTHER_SERVICES"),
    icon: "university-program",
    routes: [
      {
        id: 5,
        route: RECOMMENDATIONS_ROUTE,
        icon: "heart",
        name: t("RECOMMENDATIONS.TITLE"),
      },
      {
        id: 6,
        route: CATALOG_ROUTE,
        icon: "university-program",
        name: t("CATALOG.TITLE"),
      },
      {
        id: 15,
        route: SCHOLARSHIPS_ROUTE,
        icon: "scholarships",
        name: t("SCHOLARSHIPS.TITLE"),
        isNew: isNewFeature("2023-12-15"),
      },
      {
        id: 7,
        route: FAVORITES_ROUTE,
        icon: "saved",
        name:
          totalCountFavorites > 0
            ? `${t("FAVORITE.TITLE")} (${totalCountFavorites})`
            : t("FAVORITE.TITLE"),
      },

      {
        id: 13,
        route: RESOURCES_ROUTE,
        icon: "useful",
        name: t("HEADER.USEFUL_RESOURCES"),
      },
    ]
  },
  
];

export default getNavigationData;
