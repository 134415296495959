import { useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAction, useTranslate } from "@helpers/hooks";
import { Icon, Image } from "@components/common";
import kaspiLogo from "../../assets/kaspikz-logo-small-red.png";
import './KaspiPayment.scss';

const KaspiPayment = () => {
    const {t} = useTranslate();
    const { type, courseId, packageId, courseName, packageType, price } = useParams();
    const { kaspiMockCreate, kaspiCreate } = useAction();
    const { isKaspiPaymentLoading, kaspiPayment} = useSelector(state => state.courses);
    const formRef = useRef();

    useEffect(() => {
        if (type === "course") {
            kaspiCreate({ course_id: courseId, package_type: packageId });
        }
        if (type === "mock") {
            kaspiMockCreate({"mock_exam": courseId});
        }
    }, [])

    
    const DATA_FOR_KASPI = {
        TranId: String(kaspiPayment?.txn_id),
        OrderId: String(kaspiPayment?.order_id),
        Amount: +kaspiPayment?.sum * 100,
        Service: "SMARTPREP",
        returnUrl: type === "course" ? `https://dashboard.smartestprep.com/courses/${courseId}/sections/0/onboarding` : `https://dashboard.smartestprep.com/mock/`,
        refererHost: "dashboard.smartestprep.com",
        GenerateQrCode: true,
    }

    return (
        <div className="kaspi-payment">
            <div className="kaspi-payment__info">
                <h3>{type === "course" ? t("KASPI.COURSE_NAME") :t("KASPI.MOCK_NAME")}:&nbsp;<span>{courseName}</span></h3>
                {
                    type === "course" &&
                    <h3>{t("KASPI.PACKAGE_TYPE")}:&nbsp;<span>{packageType}</span></h3>
                }
                <h3>{t("KASPI.PRICE")}:&nbsp;<span>{+price} KZT</span></h3>
            </div>
            { (!isKaspiPaymentLoading && kaspiPayment) 
            ? <form ref={formRef} action="https://kaspi.kz/online" method="post" id="kaspikz-form">
                <input type="hidden" name="TranId" value={DATA_FOR_KASPI.TranId} />
                <input type="hidden" name="OrderId" value={DATA_FOR_KASPI.OrderId} />
                <input type="hidden" name="Amount" value={DATA_FOR_KASPI.Amount} />
                <input type="hidden" name="Service" value={DATA_FOR_KASPI.Service} />
                <input type="hidden" name="returnUrl" value={DATA_FOR_KASPI.returnUrl} />
                <button type="submit" className="kaspi-payment__btn">
                    {/* <Image src={kaspiLogo} className="kaspi-payment__logo" />
                    {t("KASPI.PAY")} */}
                     <div className="mock__card-popup-bottom"> <Icon name="kaspipay" /> </div>
                </button>
            </form>
            : <p>loading...</p>}
        </div>
    );
}

export default KaspiPayment;
